import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { h } from "vue";

const Layout = () => import("@/views/Layout");
const Home = () => import("@/views/home");
const TopCategory = () => import("@/views/category/index");
const SubCategory = () => import("@/views/category/sub");
const Goods = () => import("@/views/goods/index");
const Cart = () => import("@/views/cart/index");

const Login = () => import("@/views/login/index");
const LoginCallback = () => import("@/views/login/callback");

const Checkout = () => import("@/views/member/pay/checkout");
const Pay = () => import("@/views/member/pay/index");
const PayResult = () => import("@/views/member/pay/result");

const MemberLayout = () => import("@/views/member/Layout");
const MemberHome = () => import("@/views/member/home");
const MemberOrder = () => import("@/views/member/order");
const MemberOrderDetail = () => import("@/views/member/order/detail");  

// 路由规则
const routes = [
  // 一级路由布局容器
  {
    path: "/",
    component: Layout,
    name: "首页",
    children: [
      // { path: "/", component: Home, redirect: "/imagePage", name: "首页" },
      { path: "/category/:id/:level", component: TopCategory ,name:"辅具及设施列表"},

      { path: "/product/:id", component: Goods ,name:"辅具及设施详情"},
      { path: "/cart", component: Cart ,name:"购物车"},
      { path: "/member/checkout/:orderNo", component: Checkout,name:"填写订单" },
      { path: "/member/pay", component: Pay },
      { path: "/pay/callback", component: PayResult },
      {
        path: "/homeindex",
        name: "公司介绍",
        component: () => import("@/views/HomeIndex/index.vue")
      },
      {
        path: "/production",
        name: "产品介绍",
        component: () => import("@/views/HomeIndex/production.vue")
      },
      {
        path: "/ours",
        name: "联系我们",
        component: () => import("@/views/HomeIndex/ours.vue")
      },

      {
        path: "/apply",
        name: "辅具申请",
        component:Home,
        children:[
          {
            path: "/",
            name: "辅具申请",
            component:Home
          },
        ]
      },
      {
        path: "/trainVideo",
        name: "辅具训练",
        component: () => import("@/views/trainVideo/index"),
        children: [
        
          {
            path: "trainVideoList",
            name: "辅具训练视频",
            component: () => import("@/views/trainVideo/trainVideoList")
          }
        ]
      },
      {
        path: "/assess",
        name: "评估系统",
        component: () => import("@/views/assess/index")
      },
      {
        path: "/category/sub",
        name: "职业技能培训",
        // component: () => import("@/views/train/index"),
        component:SubCategory,
        children: [
          {
            path: "/",
            name: "职业技能培训",
            // component: () => import("@/views/train/index")
            component:SubCategory,
          },
          {
            path: "/category/sub/:id", 
            name:'职业分类',
            component: SubCategory
          },
          {
            path: "trainDetail",
            name: "培训详情",
            component: () => import("@/views/train/trainDetail")
          }
        ]
      },
      {
        path: "/member",
        component: MemberLayout,
        name:'个人中心',
        children: [
          { path: "/member", component: MemberHome },
          // { path: '/member/order', component: MemberOrder },
          // { path: '/member/order/:id', component: MemberOrderDetail }
          {path:'/member/address',component:()=>import('@/views/home/address.vue'),name:"地址管理"},
          {path:'/member/userinfo',component:()=>import('@/views/home/userInfo.vue'),name:"个人信息"},
          {path:'/member/setting',component:()=>import('@/views/home/safeSetting.vue'),name:"安全设置"},
    
          {
            path: "/member/order",
            name:'我的订单',
            // 创建一个RouterView容器形成嵌套关系
            component: { render: () => h(<RouterView />) },
            children: [
              { path: "", component: MemberOrder },
              { path: ":id", component: MemberOrderDetail ,name:'订单详情'}
            ]
          }
        ]
      }
    ]
  },
  // { path: "/imagePage", component: () => import("@/views/home/imagePage") },
  { path: "/login", component: Login },
  { path: "/login/callback", component: LoginCallback }
];

// vue2.0 new VueRouter({}) 创建路由实例
// vue3.0 createRouter({}) 创建路由实例
const router = createRouter({
  // 使用hash的路由模式
  history: createWebHashHistory(),
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior() {
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return { left: 0, top: 0 };
  }
});

// 前置导航守卫
router.beforeEach((to, from, next) => {
  // 需要登录的路由：地址是以 /member 开头
  const { profile } = store.state.user;
  if (!profile.token && to.path.startsWith("/member")) {
    return next("/login?redirectUrl=" + encodeURIComponent(to.fullPath));
  }
  next();
});

export default router;
