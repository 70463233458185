import { checkAllCart, getNewCartGoods, insertCart, mergeCart, updateCart } from '@/api/cart'
import { deleteCartId, getCartList, getCartNum, getCartTotalNum } from '@/api/home'
import Message from '@/components/library/Message'
import { message } from 'ant-design-vue'

// 购物车模块
export default {
  namespaced: true,
  state () {
    return {
      // 购物车商品列表
      list: [],
      chooseList: []
      // orderDetails: []
    }
  },
  getters: {
    // 有效商品列表
    validList (state) {
      // 有效商品：库存大于0  stock  商品有效标识为  true  isEffective
      return state.list.filter(goods => goods.stock)
    },
    // 有效商品总件数
    validTotal (state, getters) {
      return getters.validList.reduce((p, c) => p + c.cartNum, 0)
    },
    // 有效商品总金额
    validAmount (state, getters) {
      return (getters.validList.reduce((p, c) => p + c.price * 100 * c.cartNum, 0) / 100).toFixed(2)
      // return getters.validList.reduce((p, c) => p + Math.round(c.nowPrice * 100) * c.count, 0) / 100
    },
    // 无效商品列表
    invalidList (state) {
      // return state.list.filter(goods => goods.stock <= 0 || !goods.isEffective)
    },
    // 已选商品列表
    selectedList (state, getters) {
      const arr = []
      state.chooseList.forEach(e => {
        state.list.forEach(sonE => {
          if (sonE.id === e) {
            arr.push(sonE)
          }
        })
      })
      return arr
    },
    // 已选商品总件数
    selectedTotal (state, getters) {
      let num = 0
      getters.selectedList.forEach(sonE => {
        num += sonE.cartNum
      })
      return num
    },
    // 已选商品总金额
    selectedAmount (state, getters) {
      return getters.selectedList.reduce((p, c) => p + Math.round(c.price * 100) * c.cartNum, 0) / 100
    },
    // 是否全选
    isCheckAll (state, getters) {
      // console.log('getters.  ----->  ', getters.selectedList)
      return getters.validList.length !== 0 && state.chooseList.length === getters.validList.length
    },
    getChooseList (state) {
      return state.chooseList.map(item => ({ shoppingCartId: item }))
    }
  },
  mutations: {
    // 加入购物车
    insertCart (state, payload) {
      // 约定加入购物车字段必须和后端保持一致 payload对象 的字段
      // 它们是：id skuId name attrsText picture price nowPrice selected stock count isEffective
      // 插入数据规则：
      // 1. 先找下是否有相同商品
      // 2. 如果有相同的商品，查询它的数量，累加到payload上，再保存最新位置，原来商品需要删除
      // 3. 如果没有相同商品，保存在最新位置即可
      const sameIndex = state.list.findIndex(goods => goods.skuId === payload.skuId)
      if (sameIndex > -1) {
        const count = state.list[sameIndex].count
        payload.count += count
        // 删除原来
        state.list.splice(sameIndex, 1)
      }
      // 追加新的
      state.list.unshift(payload)
    },
    // 修改购物车商品
    updateCart (state, goods) {
      // goods 商品信息：nowPrice stock isEffective
      // goods 商品对象的字段不固定，对象中有哪些字段就改哪些字段，字段的值合理才改
      // goods 商品对象 必需有SKUID
      const updateGoods = state.list.find(item => item.skuId === goods.skuId)
      for (const key in goods) {
        if (goods[key] !== undefined && goods[key] !== null && goods[key] !== '') {
          updateGoods[key] = goods[key]
        }
      }
    },
    // 删除购物车商品
    deleteCart (state, skuId) {
      const index = state.list.findIndex(item => item.skuId === skuId)
      state.list.splice(index, 1)
    },
    // 设置购物车
    setCart (state, payload) {
      // payload 为空数组，清空。为又值数组，设置。
      console.log('---', payload)
      state.list = payload
      console.log(payload)
    },
    setChoose (state, payload) {
      console.log('payload  ----->  ', payload)
      // payload 为空数组，清空。为又值数组，设置。
      // state.chooseList = payload
      console.log(state)
      if (!state.chooseList.includes(payload)) {
        state.chooseList.push(payload)
        // state.orderDetails.push({ shoppingCartId: payload })
      } else {
        state.chooseList = state.chooseList.filter(item => item !== payload)
        // state.orderDetails = state.orderDetails.filter(item => item.shoppingCartId !== payload)
      }
      console.log(state.chooseList)
      // console.log(state.orderDetails)
    },
    changeChooseAll (state) {
      if (state.chooseList.length == state.list.length) {
        state.chooseList = []
      } else {
        // console.log('getters.validList  ----->  ', getters.validList)
        const ids = state.list.map(item => item.id)
        state.chooseList = ids
      }
    }

  },
  actions: {
    // 合并购物车
    async mergeCart (ctx) {
      // 准备合并的参数
      const cartList = ctx.state.list.map(goods => {
        return {
          skuId: goods.skuId,
          selected: goods.selected,
          count: goods.count
        }
      })
      await mergeCart(cartList)
      // 合并成功，清空本地购物车
      ctx.commit('setCart', [])
    },
    // 修改规格
    updateCartSku (ctx, { oldSkuId, newSku }) {
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          // 1. 找出旧的商品信息
          // 2. 删除旧商品数据
          // 3. 原先商品的数量+新skuId
          // 4. 添加新的商品
          const oldGoods = ctx.state.list.find(item => item.skuId === oldSkuId)
          deleteCart([oldGoods.skuId]).then(() => {
            return insertCart({ skuId: newSku.skuId, count: oldGoods.count })
          }).then(() => {
            return ctx.dispatch('findCart')
          }).then(data => {
            ctx.commit('setCart', data.result)
            resolve()
          })
        } else {
          // 未登录
          // 1. 找出旧的商品信息
          // 2. 删除旧商品数据
          // 3. 根据新的sku信息和旧的商品信息，合并成一条新的购物车商品数据
          // 4. 添加新的商品
          const oldGoods = ctx.state.list.find(item => item.skuId === oldSkuId)
          ctx.commit('deleteCart', oldSkuId)
          const { skuId, price: nowPrice, specsText: attrsText, inventory: stock } = newSku
          const newGoods = { ...oldGoods, skuId, nowPrice, attrsText, stock }
          ctx.commit('insertCart', newGoods)
          resolve()
        }
      })
    },
    // 批量删除
    batchDeleteCart (ctx, isClear) {
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          // const ids = ctx.getters[isClear ? 'invalidList' : 'selectedList'].map(item => item.skuId)
          const id = { ids: ctx.state.chooseList }
          deleteCartId(id).then((res) => {
            console.log('res---', res)
            Message({ type: 'success', text: res.message })
            ctx.state.chooseList = []

            return ctx.dispatch('findCart')
          })
          // .then(data => {
          //   ctx.commit('setCart', data.result)
          //   resolve()
          // })
        } else {
          // 未登录
          // 找出选中的商品列表，遍历调用删除的mutations
          // isClear 未 true  删除失效商品列表，否则事选中的商品列表
          ctx.getters[isClear ? 'invalidList' : 'selectedList'].forEach(item => {
            ctx.commit('deleteCart', item.skuId)
          })
          resolve()
        }
      })
    },
    // 全选与取消全选
    checkAllCart (ctx, selected) {
      console.log('ctx  ----->  ', ctx.getters.validList)
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          const ids = ctx.getters.validList.map(item => item.id)
          ctx.state.chooseList = ids
          // checkAllCart({ selected, ids }).then(() => {
          //   console.log('ids  ----->  ', ids)
          //   return findCart()
          // }).then(data => {
          //   ctx.commit('setCart', data.result)
          //   resolve()
          // })
        } else {
          // 未登录
          ctx.getters.validList.forEach(goods => {
            ctx.commit('updateCart', { skuId: goods.skuId, selected })
          })
          resolve()
        }
      })
    },
    // 修改购物车（选中状态，数量）
    updateCart (ctx, payload) {
      // payload 需要：必需有skuId  可能：selected  count
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          getCartNum(payload).then(() => {
            return ctx.dispatch('findCart')
          }).then(data => {
            ctx.commit('setCart', data.data)
            resolve()
          })
        } else {
          // 未登录
          ctx.commit('updateCart', payload)
          resolve()
        }
      })
    },
    // 删除购物车
    deleteCart (ctx, payload) {
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          deleteCartId(payload).then((res) => {
            console.log('res--->', res)
            Message({ type: 'success', text: res.message })
            return ctx.dispatch('findCart')
          })
        } else {
          // 未登录
          // 单条删除 payload 现在  就是skuId
          ctx.commit('deleteCart', payload)
          resolve()
        }
      })
    },
    // 加入购物车
    insertCart (ctx, payload) {
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          insertCart({ skuId: payload.skuId, count: payload.count }).then(() => {
            return ctx.dispatch('findCart')
          }).then(data => {
            ctx.commit('setCart', data.result)
            resolve()
          })
        } else {
          // 未登录
          ctx.commit('insertCart', payload)
          resolve()
        }
      })
    },
    // 获取商品列表
    findCart (ctx) {
      return new Promise((resolve, reject) => {
        if (ctx.rootState.user.profile.token) {
          // 已登录
          getCartList(true).then(data => {
            if (data.data.length === 0) {
              ctx.commit('setCart', data.data)
              getCartTotalNum()
            } else {
              ctx.commit('setCart', data.data[0].cartInfoList)
              getCartTotalNum()
              console.log('cartlisttwo--->', data)
            }
            // resolve()
          })
        } else {
          // 未登录
          // 同时发送请求（有几个商品发几个请求）等所有请求成功，一并去修改本地数据。
          // Promise.all(promise数组).then((dataList)=>{})  同时发请求，所有请求成功，得到所有成功结果
          // Promise.resolve() Promise.reject() new Promise()
          // Promise.race(promise数组).then((data)=>{}) 同时发请求，最快的请求成功，得到成功结果
          // const promiseArr = ctx.state.list.map(goods => {
          //   return getNewCartGoods(goods.id)
          // })
          // // dataList成功结果的集合，数据顺序和promiseArr顺序一致，它又是根据state.list而来
          // Promise.all(promiseArr).then(dataList => {
          //   // 更新本地购物车
          //   dataList.forEach((data, i) => {
          //     ctx.commit('updateCart', { skuId: ctx.state.list[i].id, ...data.data[0].cartInfoList })
          //   })
          //   // 调用resolve代表操作成功
          //   resolve()
          // })
          console.log('nologin---------')
          Message({ type: 'warn', text: '请先登录' })
        }
      })
    }
  }
}
