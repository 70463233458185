// 小兔鲜常量数据

// 默认9个顶级分类
export const topCategory = [

  {
    name: '首页',
    imgSrc: require('@/assets/images/navIcon/fjsq.png'),
    activeImgSrc: require('@/assets/images/navIcon/fjsq-active.png'),
    path: '/apply',
    id: "shouye"
  },
  {
    name: '辅具训练',
    imgSrc: require('@/assets/images/navIcon/fjxl.png'),
    activeImgSrc: require('@/assets/images/navIcon/fjxl-active.png'),
    path: '/trainVideo',
    id: "fjxl"
  },
  {
    name: '职业技能培训',
    imgSrc: require('@/assets/images/navIcon/zyjnxl.png'),
    activeImgSrc: require('@/assets/images/navIcon/zyjnxl-active.png'),
    activeImgSrc2:require('@/assets/images/tobottom.png'),
    imgSrc2:require('@/assets/images/tobottom2.png'),
    path: '',
    id: "zyjnxl",
    open:false,
    children:[
      {
        id:1,
        name:'老年人能力评估师',
        // picture:require('@/assets/images/tobottom2.png')
      },
      {
        id:2,
        name:'养老管理员',
        // picture:require('@/assets/images/tobottom2.png')
      },
      {
        id:3,
        name:'康复辅具技术咨询师',
        // picture:require('@/assets/images/tobottom2.png')
      },
    ]
  },
  {
    name: '评估系统',
    imgSrc: require('@/assets/images/navIcon/pgxt.png'),
    activeImgSrc: require('@/assets/images/navIcon/pgxt-active.png'),
    path: '/assess',
    id: "pgxt"
  },
  {
    name: '公司介绍',
    imgSrc: require('@/assets/images/navIcon/fjsq.png'),
    activeImgSrc: require('@/assets/images/navIcon/fjsq-active.png'),
    path: '/homeindex',
    id: "home"
  },
  {
    name: '产品介绍',
    imgSrc: require('@/assets/images/navIcon/fjsq.png'),
    activeImgSrc: require('@/assets/images/navIcon/fjsq-active.png'),
    path: '/production',
    id: "production"
  },
  {
    name: '联系我们',
    imgSrc: require('@/assets/images/navIcon/fjsq.png'),
    activeImgSrc: require('@/assets/images/navIcon/fjsq-active.png'),
    path: '/ours',
    id: "ours"
  },
]

export const leftCategory = [
  {
    name:'智力类辅具',
    id:1,

  },
  {
    name:'精神类辅具',
    id:2,

  },
  {
    name:'言语类辅具',
    id:3,

  },
  {
    name:'听力类辅具',
    id:4,

  },
  {
    name:'视力类辅具',
    id:5,

  },
  {
    name:'肢体类辅具',
    id:6,

  },
]

// 订单状态
export const orderStatus = [
  { name: 'all', label: '全部订单' , status:-1},
  { name: 'unpay', label: '待付款' , status:0},
  { name: 'deliver', label: '待发货' , status:1},
  { name: 'receive', label: '待收货' , status:2},
  // { name: 'comment', label: '待评价' },
  { name: 'complete', label: '已完成' , status:3},
  { name: 'cancel', label: '已取消' , status:4}
]

export const cancelReason = [
  '配送信息有误',
  '商品买错了',
  '重复下单/误下单',
  '忘记使用优惠券、兔币等',
  '其他渠道价格更低',
  '不想买了'
]


export const homeIcons = [
  {
    name:'大健康服务平台',
    id:1,
    imgSrc:require('@/assets/home_index/dajiank.svg')
  },
  {
    name:'院外延护系统',
    id:2,
    imgSrc:require('@/assets/home_index/yanhuxt.svg')
  },
  {
    name:'上门服务系统',
    id:3,
    imgSrc:require('@/assets/home_index/shangmen.svg')
  },
  {
    name:'辅具租售平台',
    id:4,
    imgSrc:require('@/assets/home_index/fuju.svg')
  },
  {
    name:'体检系统',
    id:5,
    imgSrc:require('@/assets/home_index/tijian.svg')
  },
  {
    name:'筛查评估系统',
    id:6,
    imgSrc:require('@/assets/home_index/shaicha.svg')
  },
  {
    name:'互联网医院系统',
    id:7,
    imgSrc:require('@/assets/home_index/hospital.svg')
  },
  {
    name:'其他',
    id:8,
    imgSrc:require('@/assets/home_index/other.svg')
  },
]
