// 提供首页相关API函数
import request from '@/utils/request'

/**
 * 获取品牌
 * @param {Integer} limit - 品牌个数
 * @returns Promise
 */
export const findBrand = (limit = 6) => {
  return request('/home/brand', 'get', { limit })
}

/**
 * 获取广告区轮播图
 * @returns Promise
 */
export const findBanner = () => {
  return request('/api/front/pagediy/info/0', 'get')
}

/**
 * 获取新鲜好物
 * @returns Promise
 */
export const findNew = () => {
  return request('/home/new', 'get')
}

/**
 * 获取人气推荐
 * @returns Promise
 */
export const findHot = () => {
  return request('/home/hot', 'get')
}

/**
 * 获取商品板块
 * @returns Promise
 */
export const findGoods = () => {
  return request('/home/goods', 'get')
}

/**
 * 获取最新专题
 * @returns Promise
 */
export const findSpecial = () => {
  return request('/home/special', 'get')
}

export const getHomeHotList = () => {
  return request('/api/front/pc/index/hot', 'get')
}

export const getHomeShowList = () => {
  return request('/api/front/pc/index/category/product/show', 'get')
}

export const getHomeCategory = () => {
  return request('/api/front/pc/index/cache/tree', 'get')
}

export const postCategory = (data) => {
  return request('/api/front/pc/index/getproduct', 'post', data)
}

export const getDetail = (data) => {
  return request('/api/front/pc/index/getproductdetail', 'get', data)
}

export const addCart = (data) => {
  return request('api/front/cart/add', 'post', data)
}

export const getCartTotalNum = () => {
  return request('/api/front/cart/count?numType=true&type=total', 'get')
}

export const getCartList = (data) => {
  return request('/api/front/cart/list?isValid=' + data, 'get')
}

export const deleteCartId = (data) => {
  return request('/api/front/cart/delete', 'post', data)
}

export const getCartNum = (data) => {
  return request('/api/front/cart/num', 'post', data)
}

export const getMemberRecord = () => {
  return request('/api/front/user/center/browse/record', 'get')
}
